<template>
    <section class="content">
        <table class="table table-hover" ref="tblkelas">
            <thead>
                <tr>
                    <th>NAMA PAKET</th>
                    <th>UJIAN</th>
                    <th>TYPE</th>
                    <th>STATUS</th>
                    <th>ACTION</th>
                </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
        </table>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ formTitle }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body row">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors" :key="error">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Ujian</label>
                                <input type="hidden" name="row_id" value="" v-model="form.id"/>
                                <input type="hidden" name="paket_id" v-model="form.paket_id"/>
                                <v-select :options="optUjian" v-model="form.ujian_id" :reduce="opt => opt.id" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Bobot Nilai (%)</label>
                                <input id="name" class="form-control name" v-model="form.bobot" type="text" required="required" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Index</label>
                                <input id="name" class="form-control name" v-model="form.index" type="number"/>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";

export default {
    name: "",
    components: { 
        vSelect, 
    },
    data() {
        return {
            titles: '',
            errors: [],
            optUjian: [],
            method: "",
            roles: "",
            formTitle: "Tambah Ujian",
            form: {
                ujian_id: "",
                bobot: "",
                index: "",
                paket_id: ''
            },
        };
    },
    created: function () {
        this.roles = this.$route.meta.roles;
        console.log("load initial data", this.$route);

        authFetch('/siswa/ujian/ujian_opt')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optUjian = js.data;
            })

        authFetch('/siswa/ujian/ujian_paket_info/'+this.$route.params.id)
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.titles = js.data.nama_paket;
                $('.card-title').html('List Ujian Paket : <span class="badge badge-info">' + this.titles + '</span>');
            })

        this.form.paket_id = this.$route.params.id
    },
    methods: {
        handleClick(e) {
            if (e.target.matches(".link-role")) {
                this.$router.push({ path: "/permit/" + e.target.dataset.id });
                return false;
            }
            if (e.target.closest("button")) {
                var btnEl = $(e.target).closest("button").get(0);
                if (btnEl.dataset.action == "detail") {
                    var id = btnEl.dataset.id;
                    if (btnEl.dataset.typeId == 4 && btnEl.dataset.mapelId == 34 && btnEl.dataset.isPsikotes == 1) {
                        this.$router.push('/detail-ujian/psikotes/'+id);
                    } else {
                        this.$router.push('/detail-ujian/'+id);
                    }
                }
                return false;
            }
        },
        submitForm: function (ev) {
          const e = this.$refs;

          var data = Object.keys(this.form)
            .map(
              (key) =>
                encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
            )
            .join("&");
          var urlSubmit = "/siswa/ujian/ujian_paket_detail";
          if (this.method == "PUT")
            urlSubmit = "/siswa/ujian/ujian_paket_detail/" + this.form.id;

          authFetch(urlSubmit, {
            method: this.method,
            body: data,
          })
            .then((res) => {
              if (res.status === 201) {
              } else if (res.status === 400) {
              }
              return res.json();
            })
            .then((js) => {
              this.errors = [];
              if (!js.success) {
                console.log(js.details);

                for (var key in js.details) {
                  if (js.details.hasOwnProperty(key)) {
                    this.errors.push(js.details[key]);
                  }
                }
                Swal.fire({
                    icon: 'info',
                    title: 'Informasi',
                    text: 'Data tidak tersimpan!'
                })
                return;
              }
              this.table.api().ajax.reload();
              $(e.formDialog).modal("hide");
              Swal.fire({
                    icon: 'success',
                    title: 'Informasi',
                    text: 'Data tersimpan!'
                })
            });

          ev.preventDefault();
        },
    },
    mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tblkelas, {
        title: "",
        roles: this.$route.params.roles,
        ajax: "/siswa/ujian/ujian_paket/"+this.$route.params.id,
        columns: [
            { data: "nama_paket" },
            { data: "ujian_title" },
            { data: "type_name" },
            { data: "active" },
            { data: "action",
            render: function (data, type, row, meta) {
                return `<div class="btn-group">
                <button type="button" class="btn btn-sm btn-outline-info" data-action="detail" data-id="` + row.ujian_id +`" data-type-id="` + row.type_id +`" data-mapel-id="`+row.mapel_id+`" data-is-psikotes="`+row.is_psikotes+`" ><i class="fas fa-eye"></i>Detail</button>
                </div>`
            } },
        ],
        paramData: function(d) {
            // if (self.$route.params.id) {
            //     d.program_id = self.$route.params.id;
            // } else {
            //     d.program_id = 0;
            // }
        },
        // filterBy: [0],
        scollx: true,
        filter: false,
        rowCallback: function (row, data) {
            if (data.active == 't') {
                $('td:eq(3)', row).html('<span class="badge badge-success">True</span>');
            } else {
                $('td:eq(3)', row).html('<span class="badge badge-error">False</span>');
            }
        },
        buttonClick: (evt) => {
            if (evt.role == "create") {
              self.form = {
                ujian_id: "",
                bobot: "",
                index: ""
              };
              this.form.paket_id = this.$route.params.id
              self.method = "POST";
              self.errors = [];
              self.formTitle = "Tambah Ujian";
              $(e.formDialog).modal("show");
            } else if (evt.role == "update" && evt.data) {
              self.form = evt.data;
              this.form.paket_id = this.$route.params.id
              self.method = "PUT";
              self.errors = [];
              self.formTitle = "Edit Ujian";
              $(e.formDialog).modal("show");
            } else if (evt.role == "delete" && evt.data) {
              // self.form = evt.data;
              Swal.fire({
                title: "Hapus data?",
                icon: "question",
                denyButtonText: '<i class="fa fa-times"></i> Hapus',
                showCancelButton: true,
                showDenyButton: true,
                showConfirmButton: false,
              }).then((result) => {
                if (result.isDenied) {
                  authFetch("/siswa/ujian/ujian_paket_detail/" + evt.data.id, {
                    method: "DELETE",
                    body: "id=" + evt.data.id,
                  })
                    .then((res) => {
                      return res.json();
                    })
                    .then((js) => {
                      this.table.api().ajax.reload();
                    });
                }
              });
            }
        },
    });
},
};
</script>
